import { BASE_CONF } from "./base";

export const environment = {
  ...BASE_CONF,
  production: true,
  host: 'https://smtcc.madretierra.gob.bo/api',
  captchaKey: 'SET_PUBLIC_KEY_CAPTCHA',
  captchaEnabled: false,
  handsontableKey: 'non-commercial-and-evaluation',
  urlGeoserver: 'https://geoserver.madretierra.gob.bo/geoserver',
  workspaceGeoserver: 'smtcc'
};
